<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter adj_sur">
      <div class="track_trace_adj">
        <div class="label_element_cover">
          <label>Enter</label>
          <span>
            <input type="text" placeholder="Search by Receipt No/Customer Ref/AWB No"
              title="Search by Receipt No/Customer Ref/AWB No" class="searchtext" v-model="receiptNo"
              @keyup.enter="searchReceiptNo" @keydown="nameKeydown($event)" />
          </span>
        </div>
        <div class="label_element_cover">
          <label>Filter By Date</label>
          <flat-pickr class="date_for_pos_fixed" v-model="daterange" :config="configDate" placeholder="Date"
            name="birthdate" @on-close="dateChanged" @on-open="cleardatepicker"></flat-pickr>
        </div>
        <div class="label_element_cover">
          <label>Filter By Status</label>
          <select v-model="status" @change="filterStatus">
            <option value disabled="disabled" selected="selected">Select status</option>
            <option value="all">All</option>
            <option value="Created">Created</option>
            <option value="Booked">Booked</option>
            <option value="Allocated">Allocated</option>
            <option value="Delivered">Delivered</option>
            <option value="Encoded">Encoded</option>
            <option value="Dispatched">Dispatched</option>
            <option value="Scan Run">Scan Run</option>
            <option value="Not Home">Not Home</option>
            <option value="Received">Received</option>
            <option value="RTS">RTS</option>
            <option value="Transmitted">Transmitted</option>
            <option value="WC">WC</option>
            <option value="Error">Error</option>
            <option value="Label Generated">Label Generated</option>
            <option value="Manifested">Manifested</option>
            <!-- <option value="Label Printed">Label Printed</option> -->
            <option value="Returned to mail room">Returned to mail room</option>
            <option value="Returned to print room">Returned to print room</option>
            <option value="Refused delivery">Refused delivery</option>
            <option value="Delivered damaged">Delivered damaged</option>
            <option value="No access to location">No access to location</option>
            <option value="Unable to find location">Unable to find location</option>
            <option value="Unable to contact recipient">Unable to contact recipient</option>
            <option value="No such person at address">No such person at address</option>
            <option value="Picked up">Picked up</option>
            <option value="Unable to access">Unable to access</option>
            <option value="No response at location">No response at location</option>
            <option value="Unable to access location">Unable to access location</option>
            <option value="Item not available">Item not available</option>
          </select>
        </div>
        <div class="label_element_cover">
          <label>Filter By Client</label>
          <select v-model="client" @change="filterClient">
            <option value disabled="disabled" selected="selected">Select Client</option>
            <option value="all">All</option>
            <option value="base">Base</option>
            <option value="personal">Personal</option>
          </select>
        </div>
      </div>
    </div>

    <loader v-if="loading" />

    <section class="print_hide" v-if="!loading && show_data && courier.length > 0">
      <div v-for="(data, i) in courier" :key="i">
        <!-- <pre>{{ data }}</pre> -->
        <div class="container">
          <div class="row">
            <div class="col-6 invoice-template">
              <div :ref="`item_${i}`">
                <outbound-invoice :data="data" />
              </div>
            </div>
            <div class="col-6 option-btn-section">
              <div class="two_buttons_container">
                <p class="bt bt_black">{{ data.status }}</p>
                <a href="javascript:void(0)" class="bt_print" @click="printPDF(i, data)">Print Invoice</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <paginate v-if="pages > 0" v-show="!loading" v-model="current_page" :page-count="pages"
      active-class="paginate-active" :click-handler="fetchCouriers" prev-link-class="paginate-previous"
      next-link-class="paginate-next" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'"
      :no-li-surround="true"></paginate>

    <h4 v-if="!loading && show_data && courier.length == 0">No data found</h4>

  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

import OutboundInvoice from '../invoices/OutboundInvoice.vue';

// import * as jsPDF from 'jspdf'
// import html2canvas from "html2canvas"

export default {
  name: "OutboundInvoiceReport",
  components: {
    flatPickr,
    OutboundInvoice
  },
  data() {
    return {
      receiptNo: "",
      daterange: moment(new Date()).format('YYYY-MM-DD'),
      status: "",
      client: "all",
      courier: [],
      pages: 0,
      configDate: {
        mode: "range",
        format: "d-m-Y",
        altFormat: "d-m-Y",
        altInput: true
      },
      current_page: 0,
      show_data: false,
      loading: false,
      post_data: {
        value: "",
        statusfilter: "",
        clientFilter: "",
        from: moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD'),
        isInvoiceReport:true
      },
    };
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('DD-MM-YYYY')
    },
  },
  created() {
    this.$emit("breadCrumbData", ["Report", "Outbound Invoice"]);
  },
  methods: {
    nameKeydown(e) {
      if (/^\W$/.test(e.key) && e.keyCode != 32) {
        e.preventDefault();
      }
    },
    searchReceiptNo(receiptNo) {
      this.post_data.value = this.receiptNo;
      this.fetchCouriers();
    },
    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
      this.fetchCouriers();
    },
    cleardatepicker() {

      this.daterange = "";
    },
    filterStatus() {
      this.post_data.statusfilter = this.status;
      this.fetchCouriers();
    },
    filterClient() {
      this.post_data.clientFilter = this.client;
      this.fetchCouriers();
    },
    fetchCouriers(page = 1) {
      this.current_page = page;
      this.show_data = true;
      this.loading = true;
      this.axios
        .post("/api/courier/" + page + "/listingAndFilter", this.post_data)
        .then(response => {
          if (response.data.status == "ok") {
            // this.courier = response.data.couriers.list;
            this.courier = response.data.couriers.list.map(element => {
              element.show_view_popup = false;
              element.label_url = element.airway_bill && element.file_name ? process.env.VUE_APP_API_URL + "/labels/" + element.file_name : "";
              element.commercial_url = element.airway_bill && element.commercial_file_name ? process.env.VUE_APP_API_URL + "/labels/" + element.commercial_file_name : "";

              element.mailroom_branch = '';
              element.mailroom_address = '';

              if(element.requester && element.requester.branch) {

                if (element.requester.branch.name.toUpperCase().includes('KWM VIC')) {
                  element.mailroom_branch = 'Melbourne';
                  }
                  
                  if (element.requester.branch.name.toUpperCase().includes('KWM NSW')) {
                    element.mailroom_branch = 'Sydney';
                  }

                  element.mailroom_address = element.requester.branch.address_1
              }

              return element;
            });
            this.pages = response.data.couriers.pages;
          }
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    printPDF(i, data) {
      const refName = `item_${i}`;

      const content = this.$refs[refName][0];

      // const doc = new jsPDF({
      //   orientation: "portrait",
      //   unit: "px",
      //   format: "a4",
      //   hotfixes: ["px_scaling"],
      // });

      // html2canvas(content, {
      //   width: doc.internal.pageSize.getWidth(),
      //   height: doc.internal.pageSize.getHeight(),
      //   scale: 1.5,
      // }).then((canvas) => {
      //   const img = canvas.toDataURL("image/png", 1.0);

      //   doc.addImage(
      //     img,
      //     "PNG",
      //     30,
      //     0,
      //     doc.internal.pageSize.getWidth(),
      //     doc.internal.pageSize.getHeight()
      //   );

      //   doc.save(`outbound-invoice${data.transaction_no ? '-' + data.transaction_no : ''}.pdf`);
      // });
    }
  }
};
</script>
<style lang="scss">
.transmittal_slip_filter .track_trace_adj {
  display: flex;
}

.transmittal_slip_filter .track_trace_adj span {
  display: block;
  position: relative;

  button {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    background: none;
    right: 20px;
    top: 5px;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;

    i {
      display: inline;
      color: #fff;
      margin: 0;
      line-height: 0;
      font-size: 24px;
    }
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}


.col-7,
.col-6,
.col-5 {
  padding: 10px;
  text-align: center;
}

.col-7 {
  width: 58.333333%;
  /* 7/12 * 100 */
}

.col-6 {
  width: 50%;
  /* 6/12 * 100 */
}

.col-5 {
  width: 41.666667%;
  /* 5/12 * 100 */
}

.invoice-template {
  background-color: #ddd;
  padding: 10px;
}

.transmittal_slip_filter .track_trace_adj .searchtext {
  width: 260px !important;
  margin-left: 10px;
}

.option-btn-section {
  display: flex;
  align-items: center;
}

.two_buttons_container {



  .bt_print {
    display: block;
    color: #000;
    background: #06A5ED;
    width: 120px;
    height: 30px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 5px;
    font-weight: 400;
  }
}

.bt {
  text-align: center;
  line-height: 25px;
  border-radius: 4px;
  display: block;
  margin: 5px 0;
}

.bt_black {
  background: #063657;
  color: #fff;
}

@media screen and (max-width: 1600px) {
  .transmittal_slip_filter .track_trace_adj .searchtext {
    width: 200px !important;
    margin-left: 0;
  }
}
</style>
